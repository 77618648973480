<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          :title="'Escolha o Credor'"
          @click="resetGraficos()"
        >
          <b-col
            md="10"
            mb="2"
            class="budget-wrapper"
          >
            <v-select
              v-model="credor"
              class="mt-2"
              :options="credores"
              @input=" getCredorId"
            />
          </b-col>
        </b-card>
        <section
          v-if="recarregaGraficos"
          id="dashboard-ecommerce"
        >
          <b-card
            v-if="dadosGraficos"
          >
            <b-card>
              <ChartRemessasTotal
                :general-data="credorId"
              />
            </b-card>
            <b-card>
              <ChartRemessasAnual
                :general-data="credorId"
              />
            </b-card>
          </b-card>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ChartRemessasTotal from '../backoffice/credores/cards/charts/ChartRemessasTotal.vue'
import ChartRemessasAnual from '../backoffice/credores/cards/charts/ChartRemessasAnual.vue'

import axios from '../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    ChartRemessasTotal,
    ChartRemessasAnual,

  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      recarregaGraficos: false,
      dadosGraficos: false,
      credores: [],
      credor: null,
      credorId: null,
      selectedYear: null,
      years: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
    }
  },

  async created() {
    this.getCredores()
  },
  methods: {
    resetGraficos() {
      this.dadosGraficos = false
      this.$refs.show()
    },
    getCredorId() {
      this.credorId = this.credor.id
      this.dadosGraficos = true
      this.recarregaGraficos = true
    },
    async getCredores() {
      await axios.get('api/v1/credores/listar/false', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.credores.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
